import { ServerCIEnvironment } from '~/servers/servers';

/**
 * A list of feature flags available
 *
 * These will be used as keys to locate the actual Unleash feature flag from the `envFeatureFlagMapping` constant
 *
 * When adding new feature flags, this `enum` must be updated
 */
export enum FeatureFlag {
    GLOBAL = 'global',
    LIVE_DISPATCH_UI = 'live-dispatch-ui',
    RESET_TASKS = 'reset-tasks',
    CLIENT_TIMEZONE = 'client-timezone',
    OPERATIONAL_STOP = 'operational-stops',
    UNARRIVE_STOPS = 'unarrive-stops',
    EDIT_CAR_KIND = 'edit-car-kind',
    EDIT_EMPTY_ORDER_NUMBER = 'edit-empty-order-number',
    INITIAL_VS_ACTUAL = 'initial-vs-actual',
    PREDEFINED_EXTERNAL_TASK_TYPES = 'predefined-external-task-types',
    SNAPPED_TO_ROAD_PLANNED_LINES = 'snapped-to-road-planned-lines',
    REQUEST_EXTERNAL_ID_FOR_ROUTE_EXPORT = 'request-external-id-for-route-export',
    DISPATCH_SINGLE_TRIP_IN_RELOADS = 'dispatch-single-trip-in-reloads',
    GAP_IN_ROUTE = 'gap-in-route',
    SPLIT_TASK = 'split-task',
    UNASSIGNED_TASKS_SEARCH = 'unassigned-tasks-search',
    CREATE_TRIP = 'create-trip',
    ASSIGN_DRIVER_COLOR = 'assign-driver-color',
    INTEMPO_LOGO = 'intempo-logo'
}

/**
 * Pre-defined environment array for a single flag strategy
 */
const ANY_ENVIRONMENT = [ServerCIEnvironment.ANY];

/**
 * Pre-defined environment array for a separate environment flag strategy
 */
const DEFAULT_ENVIRONMENTS = [
    ServerCIEnvironment.STAGE,
    ServerCIEnvironment.UAT,
    ServerCIEnvironment.CN_UAT,
    ServerCIEnvironment.PRODUCTION
];

/**
 * A mapping of available Unleash feature flags to support CI environments
 *
 * This map is essential for the `getEnvFeatureFlag()` utility and `useFeatureFlag()` hook
 *
 * When adding new feature flags, this `object` must be updated
 *
 * @example
 * To use feature flags for specific environments, define as follows:
 * ```
 * [featureFlag]: [
 *     ServerCIEnvironment.STAGE,
 *     ServerCIEnvironment.CN_UAT,
 *     ServerCIEnvironment.PRODUCTION
 * ]
 * ```
 *
 * Using this strategy, you will need to define separate Unleash feature flags
 * for each environment you want to support.
 *
 * Environment feature flags must conform to the following convention:
 * ```
 * {featureFlag}-{environmentSuffix}
 * ```
 *
 * Supported suffixes are defined under `FeatureFlagEnvironmentSuffix`
 *
 * @example
 * To use feature flags for the default supported environments, define as follows:
 * ```
 * * [featureFlag]: DEFAULT_ENVIRONMENTS
 * ```
 *
 * This is the same as defining:
 * ```
 * [featureFlag]: [
 *     ServerCIEnvironment.STAGE,
 *     ServerCIEnvironment.UAT,
 *     ServerCIEnvironment.CN_UAT,
 *     ServerCIEnvironment.PRODUCTION
 * ]
 * ```
 *
 * @example
 * To just use 1 feature flag for any environment, define as follows:
 * ```
 * [featureFlag]: ANY_ENVIRONMENT
 * ```
 *
 * Using this strategy, you will need to define only one Unleash feature flag,
 * and configure for any environment as a single strategy.
 *
 * @see {@link https://gitlab.com/wisesystems/wise-web-multi-client/-/feature_flags | Available Interstellar Feature Flags}
 * @see {@link https://docs.getunleash.io/reference/sdks/react | Unleash React Documentation}
 */
export const envFeatureFlagMapping: Partial<
    Record<FeatureFlag, ServerCIEnvironment[]>
> = {
    [FeatureFlag.GLOBAL]: ANY_ENVIRONMENT,
    [FeatureFlag.LIVE_DISPATCH_UI]: DEFAULT_ENVIRONMENTS,
    [FeatureFlag.GAP_IN_ROUTE]: DEFAULT_ENVIRONMENTS,
    [FeatureFlag.RESET_TASKS]: [ServerCIEnvironment.PRODUCTION],
    [FeatureFlag.CLIENT_TIMEZONE]: [
        ...DEFAULT_ENVIRONMENTS,
        ServerCIEnvironment.DEMO
    ],
    [FeatureFlag.OPERATIONAL_STOP]: DEFAULT_ENVIRONMENTS,
    [FeatureFlag.EDIT_CAR_KIND]: ANY_ENVIRONMENT,
    [FeatureFlag.EDIT_EMPTY_ORDER_NUMBER]: ANY_ENVIRONMENT,
    [FeatureFlag.UNARRIVE_STOPS]: DEFAULT_ENVIRONMENTS,
    [FeatureFlag.INITIAL_VS_ACTUAL]: [ServerCIEnvironment.STAGE],
    [FeatureFlag.PREDEFINED_EXTERNAL_TASK_TYPES]: ANY_ENVIRONMENT,
    [FeatureFlag.SNAPPED_TO_ROAD_PLANNED_LINES]: [
        ServerCIEnvironment.PRODUCTION,
        ServerCIEnvironment.STAGE,
        ServerCIEnvironment.DEMO
    ],
    [FeatureFlag.REQUEST_EXTERNAL_ID_FOR_ROUTE_EXPORT]: [
        ServerCIEnvironment.PRODUCTION,
        ServerCIEnvironment.STAGE,
        ServerCIEnvironment.DEMO
    ],
    [FeatureFlag.DISPATCH_SINGLE_TRIP_IN_RELOADS]: [
        ServerCIEnvironment.PRODUCTION,
        ServerCIEnvironment.STAGE,
        ServerCIEnvironment.DEMO
    ],
    [FeatureFlag.SPLIT_TASK]: [
        ServerCIEnvironment.PRODUCTION,
        ServerCIEnvironment.STAGE,
        ServerCIEnvironment.DEMO
    ],
    [FeatureFlag.UNASSIGNED_TASKS_SEARCH]: [
        ...DEFAULT_ENVIRONMENTS,
        ServerCIEnvironment.DEMO
    ],
    [FeatureFlag.CREATE_TRIP]: [ServerCIEnvironment.STAGE],
    [FeatureFlag.ASSIGN_DRIVER_COLOR]: [
        ServerCIEnvironment.PRODUCTION,
        ServerCIEnvironment.STAGE,
        ServerCIEnvironment.DEMO,
        ServerCIEnvironment.UAT
    ],
    [FeatureFlag.INTEMPO_LOGO]: DEFAULT_ENVIRONMENTS
};
